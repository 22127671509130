.abt-eppr-header {
  background-color: white;
  text-align: center;
  color: black;
  border: none;
}
.faq-list {
  color: black;
  font-size: small;
  font-weight: normal;
  font-style: italic;
}
.tool_bar_faq td {
  border: 0px !important;
}
.dot_pub {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 5px;
  margin-left: 5px;
}
.img-descr {
  align-items: center;
}
.about-ep-card {
  border-radius: 5px;
  border: none;
}
