.desktop-only {
  display: none !important;
}
@media only screen and (min-width: 701px) {
  .desktop-only {
    display: block !important;
  }
}
.verticle-centre {
  padding-top: 3px !important;
  padding-bottom: 0px !important;
}

.flow-root {
  display: flow-root !important;
}
.border-left {
  border-radius: inherit !important;
}
.border-right {
  border-radius: inherit !important;
}
.hide {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.show {
  opacity: 1;
  pointer-events: all;
}
.card-gallery-img {
  border-radius: 0px;
  box-shadow: 0 0px 13px rgb(0 0 0 / 25%), 0 10px 9px rgb(0 0 0 / 22%);
  border: none;
  max-width: 70%;
}
.page-font-bottombar {
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1024px) {
  #app-bar {
    /* width: 66.5vw !important; */
    left: 16.7vw !important;
    max-height: 3px !important;
  }
  .page-font-bottombar {
    font-size: small;
  }
}
@media only screen and (max-width: 1365px) {
  #app-bar {
    width: 92.2vw !important;
    left: 4vw !important;
    max-height: 45px !important;
  }
}
@media only screen and (min-width: 1500px) {
  #app-bar {
    /* width: 82.6vw !important; */
    left: 3.5vw !important;
    max-height: 45px !important;
  }
}
@media only screen and (min-width: 1900px) {
  #app-bar {
    /* width: 83vw !important; */
    left: 3vw !important;
    max-height: 57px !important;
    bottom: 8px;
    padding: 0px 0;
  }
  .feather-75 {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 1024px) {
  .ribbon-desktop-thmbnl {
    font-size: medium !important;
    top: 82.5% !important;
    left: 77% !important;
    width: 1.5625rem !important;
    height: 6px !important;
    z-index: 99 !important;
  }
}
.fabButton {
  position: absolute !important;
  left: 0px !important;
  color: white !important;
  right: 0 !important;
  margin: 0 auto !important;
  bottom: -15px !important;
}
/* .fabButton:hover {
  background-color: #2b77a2e6 !important;
} */
@media only screen and (min-width: 1900px) {
  .ribbon-desktop-thmbnl {
    font-size: 24px !important;
    top: 92.5% !important;
    left: 87% !important;
    width: 2.5625rem !important;
    height: 39px !important;
  }
  .vertical {
    height: 32px !important;
  }
  .fabButton {
    height: 70px !important;
    width: 70px !important;
    bottom: -23px !important;
    margin-left: -8px !important;
  }

  .button-fixed-5-2 {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    padding-right: 7px;
  }

  .button-fixed3 {
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    padding-left: 7px;
  }
}
.flow-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.botton-bottombar {
  text-align: center;
  color: #edf2f7;
  border-radius: 5px !important;
  border-color: none;
  background-color: #2b78a2;
  color: #edf2f7;
}
.botton-bottombar :hover {
  background-color: #2b78a2 !important;
  color: #edf2f7 !important;
  border-radius: 5px !important;
}
.myoverlay-desktop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(21 21 21 / 49%) !important;
}
.desktop-thumbnail {
  margin: 0px;
  display: inline;
}

.padding-col-dekstop {
  width: auto;
  padding-right: 0.8rem !important;
}
.ribbon-desktop-thmbnl {
  position: absolute;
  color: white;
  text-align: center;
  cursor: pointer;
  top: 91.5%;
  left: 82%;
  height: 0.8rem;
  width: 1.5625rem;
  background-color: #2b78a2;
  border: none;
  padding-bottom: 27px;
  font-size: small;
  z-index: 99 !important;
}
.space-thumb-desktop {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.vertical {
  border-left: 2px solid white;
  height: 20px;
}

.bottomBarFullWidth {
  width: 100vw;
}
.MuiTab-root {
  min-width: auto !important;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .flow-root {
    display: block;
  }
  .MuiToolbar-root {
    display: block !important;
  }
  #app-bar {
    background-color: #000000d9 !important;
  }
  .space-thumb-desktop {
    margin-top: 8rem;
  }
  .pages-card-img {
    width: 100px !important;
    height: auto;
  }
}
