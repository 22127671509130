.news-title {
  padding: 0px;
  text-align: center;
  color: white;
  overflow: auto;
  font-size: small;
  top: auto;
}
.card-text-thumbnail {
  background-color: black;
  line-height: normal;
  max-height: 2.125rem;
}
.width-auto {
  width: auto;
  padding: 0.25rem !important;
  cursor: pointer !important;
}

.padding-row {
  padding-left: 2.2rem !important;
  padding-right: 1.5rem !important;
}
.ribbon {
  position: absolute;
  color: white;
  text-align: center;
  cursor: pointer;
  top: 88.5%;
  left: 82%;
  height: 0.625rem;
  width: 1.5625rem;
  background-color: #2b78a2;
  border: none;
  /* border-top-left-radius: 2px; */
  /* border-bottom-right-radius: 2px; */
  padding-bottom: 24px;
}

.ribbon:focus {
  background-color: white;
  color: #2b78a2;
}
.thumb-card:focus {
  opacity: 0.5 !important;
}
.thumb-card:hover {
  /* opacity: 0.7 !important;
  filter: alpha(opacity=40); */
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.modal-content {
  background-color: #03030300 !important;
  border: none;
  height: auto !important;
}
.padding-center {
  padding-top: 0.5rem;
}

@media (max-width: 900px) {
  .ribbon {
    width: 18px;
    top: 77.5%;
    height: 0px;
    left: 77%;
  }
}

@media (max-width: 700px) {
  .ribbon {
    width: 24px;
    top: 87.5%;
    height: 0px;
    left: 85%;
  }
}

.modal-content-desktop-thmbnl {
  margin-top: 100px;
}
