.cookie-card {
  width: 100% !important;
  position: absolute;
  top: 50;
  bottom: 50;
  right: 50;
  left: 50;
}
.table-cookie {
  background-color: rgb(85, 201, 247) !important;
}

.bodyCookie {
  border-style: solid;
  border-width: 2px;
  border-color: blue;
  /* min-width: 360px; */
  overflow: auto;
  background-color: white;
}
@media screen and (min-width: 700px) {
  .modalCss {
    max-width: 65vw !important;
  }
}
.back-cookie {
  opacity: 0.5 !important;
}
@media screen and (max-width: 700px) {
  .modalCss {
    max-width: 800px !important;
  }
}
.headerTable {
  background-color: lightblue !important;
}
.mouse {
  cursor: pointer;
}
.blueClr {
  color: blue;
}
.fs20 {
  font-size: 20px;
}
.fs16 {
  font-size: 16px;
}
.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}
.bold {
  font-weight: bold;
}
.textEnd {
  text-align: end;
}
.mainWidth {
  /* min-width: 360px !important; */
  overflow: hidden;
  margin: auto;
}
