@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .select-ie {
    /* display: -ms-inline-grid !important; */
  }
  .css-1d8n9bt {
    display: -ms-inline-grid !important;
  }
}
.card-body .form-group {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 768px) {
  .my_account_container {
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 425px) {
  .my_account_container {
    margin-right: 0 !important;
  }
}
.page-title-myact {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
}
.label-myacnt {
  font-size: 14px;
  display: flex;
  margin: 0 0 5px 0;
  border-bottom: none;
  font-weight: 600;
  text-align: start;
}

.footer-myacnt {
  margin-top: 1rem;
  border: 3px solid #e5e5e5;
  margin-bottom: 160px;
  float: left;
  width: 100%;
}
.footer-myacnt [href] {
  color: #825f90;
  font-size: 14px;
}
.toi-copyright-myacnt {
  text-align: center;
  color: #825f90;
  font-size: 16px;
  font-size: 14px;
}
.toi-sep {
  color: #825f90;
}
@media screen and (min-device-width: 281px) and (max-device-width: 480px) {
  .page-title-myact {
    font-size: 16px;
  }
  .myacnt-title {
    font-size: 18px;
  }
}
.myacnt-main-content {
  overflow: scroll;
}
