.text-align-end {
  text-align: end;
}
.cursor-pointer {
  cursor: pointer !important;
}
.form-control-dsearch {
  height: 40px !important;
  width: 99.5%;
  max-width: 95.5%;
  /* min-width: 43rem !important;
  max-width: 133rem !important; */
}
@media only screen and (max-width: 900px) {
  .form-control-dsearch {
    height: 40px !important;
    width: 99.5%;
    max-width: 99.5%;
    /* min-width: 43rem !important;
    max-width: 133rem !important; */
  }
}
.images-search-card {
  border-radius: 0px;
  box-shadow: 0 3px 11px rgb(0 0 0 / 45%), 0 4px 8px rgb(0 0 0 / 45%);
  border: none;
  max-height: 128px;
  /* min-height: 128px; */
  border-radius: 3px;
}
.ad-search-card {
  border-radius: 0px;
  box-shadow: 0 3px 11px rgb(0 0 0 / 45%), 0 4px 8px rgb(0 0 0 / 45%);
  border: none;
  max-height: 128px;
  /* min-height: 128px; */
  border-radius: 3px;
}
.horizontal-line-search {
  height: 1px;
  border: 0;
  color: #333;
  background-color: #333;
}
.article-card-search {
  width: 122px;
  height: auto;
  border: 1.2px solid black;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  max-height: 200px;
}

.myoverlay-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(21 21 21 / 0%) !important;
}
input.react-datepicker-ignore-onclickoutside {
  width: 91x !important;
}
input.datePickerSearch {
  width: 91px;
}
.btn-search {
  display: table;
  width: 106px;
  background: white;
  color: black;
  text-decoration: none;
  border-color: #c9bcbc;
}
.btn-search:hover {
  background: #2b78a2;
  color: white;
  border: none;
}
.input-search {
  border: 2px solid #2b78a2;
  border-radius: 8px;
  height: 2.9375rem;
}
.mymodal-search-result {
  position: fixed;
  top: 50%;
  left: 50%;
  right: 30%;
  transform: translate(-50%, -50%);
  min-width: 540px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 22px;
  outline: none;
  width: 96%;
  height: 96%;
  padding: 0px 20px 20px 20px;
  z-index: 9999999;
}
.mymodalSearch {
  position: relative;
  top: 140px;
  left: 7%;
  width: 500px;
  border: 1px solid #ccc;
  background: #fff;
  /* overflow: auto; */
  border-radius: 22px;
  outline: none;
  height: 340px;
  padding: 20px;
  z-index: 9999999;
}

@media only screen and (max-width: 700px) {
  .mymodalSearch {
    top: 60px;
    left: 10%;
    width: fit-content !important;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1023px) {
  .mymodalSearch {
    top: 150px;
    left: 12%;
    width: fit-content !important;
  }
}
@media only screen and (max-width: 569px) {
  .mymodalSearch {
    left: 0 !important;
  }
}
.date-search-result {
  display: flex;
  justify-content: flex-start;
  font-size: smaller;
}
@media only screen and (max-width: 700px) {
  .date-search-result {
    font-size: 10px;
  }
}
.justify-content-end {
  display: flex;
  justify-content: flex-end;
}
.mymodal-search-result-mob {
  position: fixed;
  top: 60px;

  left: 0;
  /* right: 30%; */
  /* transform: translate(-50%, -50%); */
  /* min-width: 540px; */
  /* border: 1px solid #ccc; */
  background: #fff;
  overflow: auto;
  border-radius: 22px;
  outline: none;
  width: fit-content;
  height: 90%;
  padding: 0px 20px 20px 20px;
  z-index: 9999999;
}
.rel1,
.rel2,
.rel3,
.rel4,
.rel5 {
  width: 7px;
  height: 7px;
  background-color: #ccc;
  margin-right: 1px;
  display: inline-block;
  margin-bottom: 1px;
  background-color: red;
}
.align-items-center {
  align-items: center;
}
.search-container {
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid grey;
  padding: 0.5rem 0;
}

@media only screen and (max-height: 450px) {
  .mymodalSearch {
    height: 100%;
  }
}
.mymodal-search-result {
}

.mymodal-search-result::-webkit-scrollbar {
  display: none;
}

.mymodal-search-result::-webkit-scrollbar-track {
  background: none; /* color of the tracking area */
}

.mymodal-search-result::-webkit-scrollbar-thumb {
  background-color: white; /* color of the scroll thumb */
}
.article-search-card {
  cursor: pointer;
  /* border-bottom: 1px solid grey; */
}
.article-search-text {
  line-height: normal;
  text-align: justify;
  margin-bottom: 0;
}
.myoverlay-search-card {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.toi-more-anchor {
  display: table;
  margin: auto;
  padding: 0.4em 1em;
  border-radius: 4px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background: #2b78a2;
  text-decoration: none;
  margin-bottom: 1rem;
}
.toi-more-anchor:hover {
  color: white;
  text-decoration: none;
}
