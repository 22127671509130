.login-btn-nbt-menu{
    background: none;
    padding: 0px 11px; 
}
.login-btn-nbt-menu:hover{
    background: none;
}
.login-inro-text{
font-size: 14px;
}
.menu-item-nbt span{
    font-size: 15px !important;
}
.login-welcome-text{
    font-size: 16px;
    color: grey;
}
.nbt-menu-list{
    border-bottom: 1px solid #c1bbbb !important;
}