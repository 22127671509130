.desktop-oldNewspaper-modal {
  display: inline;
  padding: 0px;
}

.dateModal {
  background-color: white;
  border-radius: 5px;
  width: 70vw;
  margin: auto;
}
@media (max-width: 425px) {
  .dateModal {
    width: 90vw;
  }
}

.date-modal-card {
  width: 95%;
  overflow: hidden;
  margin-bottom: 1rem;
  position: relative;
}
.date-modal-card img {
  object-position: center;
  object-fit: contain;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .date-modal-card {
    width: 8rem;
    height: auto;
  }
  .date-modal-ie {
    width: 8rem !important;
  }
}
.click-archive {
  cursor: pointer;
  font-size: 11pt;
  color: rgb(85, 26, 139);
  text-decoration: underline;
}
