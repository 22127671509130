.card-gallery {
  border-radius: 0px;
  box-shadow: 0 3px 11px rgb(0 0 0 / 45%), 0 4px 8px rgb(0 0 0 / 45%);
  border: none;
  border-radius: 4px;
}
.card-gallery-mob {
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: none;
  border-radius: 4px;
}
.gall-pad {
  padding-right: 11px;
  padding-left: 25px;
}
.gallery-heading-mob {
  font-size: 13px !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
}
@media only screen and (max-width: 1024px) {
  .gallery-article-desktop {
    font-size: 11px !important;
    padding-right: 2px !important;
    padding-left: 5px !important;
  }
  .gallery-heading-desktop {
    font-size: 15px !important;
    padding-right: 2px !important;
    padding-left: 5px !important;
  }
}
@media only screen and (min-width: 1800px) {
  .gallery-article-desktop {
    font-size: 26px !important;
  }
  .gallery-heading-desktop {
    font-size: 31px !important;
  }
}
.gallery-with-2 {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.gallery-with-1 {
  padding: 10px 0px 10px 38px;
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .card-gallery {
    border-radius: 0px;
    box-shadow: 0px 3px 11px #ccc, 0px 4px 8px #ccc;
    border: none;
    width: 250px;
    height: auto !important;
    background-size: cover;
    background-repeat: no-reapeat;
    background-position-x: 50%;
    background-position-y: 50%;
  }
  .tile-card-img {
    height: 100px !important;
    width: auto !important;
  }
  .gall-ie {
    margin-top: 8rem !important;
  }
}

@media only screen and (min-width: 701px) {
  .gall-ie {
    margin-top: 140px !important;
  }
}
.card-gallery {
  position: relative;
  width: 100%;
}

.tile-card-img {
  display: block;
  width: auto;
  height: 100px;
}

.overlay-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(78, 76, 76);
  color: white;
}
.overlay-img-mob {
  display: flex;
  text-align: center;
  position: absolute;
  bottom: -4px;
  overflow-y: auto;
  height: 37%;
  width: 100%;
  background-color: rgb(245, 245, 245);
}
.card-gallery:hover .overlay-img {
  opacity: 1;
}
.card-gallery:hover.overlay-img-initial {
  opacity: 0;
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.advertismnt-tile {
  justify-content: center;
  position: absolute;
  top: 50%;
  color: grey;
}
