.card-digest {
  border-radius: 0px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  min-height: 500px;
}
.heading-sticky-lg {
  height: 50px;
  line-height: 50px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 151px;
  z-index: 11;
  margin-top: 0;
  overflow: hidden;
  /* width: 98%; */
}
@media (min-width: 1900px) {
  .heading-sticky-lg {
    top: 162px;
  }
}
.heading-sticky-mob {
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;

  z-index: 11;
  background-color: #2b78a2;
  margin-top: 0;
  border-radius: 4px;
  overflow: hidden;
}
.horizontal-line {
  width: 100%;
  height: 1px;
  border: 0;
  color: #c0c0c0;
  background-color: #c0c0c0;
}

@media (max-width: 1024px) {
  .horizontal-line {
    width: 100%;
  }
}
.digest-card-body {
  min-height: 400px !important;
}
.frozen {
  height: 50px;
  line-height: 50px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 30.5vh;
  z-index: 9;
  margin-top: 0;
  cursor: pointer;
}
.frozen-mob-heading {
  height: 50px;
  line-height: 50px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 13.5vh;
  z-index: 11;
  margin-top: 0;
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .frozen {
    top: 15.5vh;
  }
}
@media only screen and (max-width: 1024px) {
  .frozen {
    top: 27.5vh;
  }
}
@media only screen and (max-width: 1024px) {
  .padding-ipad {
    padding-left: 33px;
  }
}
@media only screen and (min-width: 2000px) {
  .frozen {
    height: 50px;
    line-height: 50px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 21.4vh;
    z-index: 11;
    margin-top: -20px;
  }
}
.digest-view-width {
  padding-right: 10rem !important;
}
.digest-image {
  width: 250px;
  max-width: -webkit-fill-available;
  height: auto;
  -webkit-box-shadow: 5px 5px 5px #292828;
  box-shadow: 5px 5px 5px #292828;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .digest-row-ie {
    margin-top: 7rem;
  }
  .digest-col-ie {
    margin-top: 5rem;
  }
  .horizontal-line {
    width: 100%;
  }
}
